import { metaInfo } from "../../assets/data/meta-config";
import { Helmet } from "react-helmet";
export const MetaInfo = ({page_name}) => {
    const { title, description, keyword, url } = metaInfo[page_name];

    return (
        <Helmet>
            <title data-react-helmet="true">{title}</title>
            <meta data-react-helmet="true" name="description" content={description} />
            <meta name="keywords" content={keyword} />

            <link rel="canonical" href="https://darklion.studio/" />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />

            <meta property="og:image" content={url} />
            <meta property="og:image:secure_url" content={url} />

            <meta property="og:image:type" content="image/jpg" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="628" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={url} />
        </Helmet>
    );
}